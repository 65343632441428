import { getRegionId } from '@/config'
import https from '@/https/http'
import { DescTypeItem, GisItem, FenceItem, LandTypeItem, LandTypeListDto, SatelliteStageDto, SatelliteStageItem } from '@/interfaces/gis.inteface'
import { IRes, RequestDto } from '@/interfaces/common/api'
import { Region } from '@/interfaces/region.interface'

export function getGeoServiceList () { // gis服务图层列表
  return https < any, IRes<GisItem[]>>({ url: '/api/geo-service/list', method: 'GET' })
}

export function getLandTypeList () { // 土地类型列表
  return https < LandTypeListDto, IRes<LandTypeItem[]>>({ url: '/api/land-polygon/landType', method: 'GET' })
}

export function getDescTypeList () { // 变化类型列表
  return https < any, IRes<DescTypeItem[]>>({ url: '/api/report/descType', method: 'GET' })
}

export function getSatelliteStageList (params: SatelliteStageDto) { // 卫片期数列表
  return https<SatelliteStageDto, IRes<SatelliteStageItem[]>>({ url: '/api/land-polygon/objectStage', method: 'GET', params })
}

export function getRegionList () {
  return https < any, IRes<Region[]>>({
    url: '/api/region/info',
    method: 'GET',
    params: {
      region_id: getRegionId()
    }
  })
}

/**
 * 行政区域服务
 */
export function getRegionFence (params:RequestDto) { // 获取行政区域电子围栏
  return https< RequestDto, IRes<FenceItem[]>>({ url: '/api/region/fence', method: 'GET', params })
}

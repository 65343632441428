
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import log from '@/decorators/log'
import catchError from '@/decorators/catchError'
import time from '@/decorators/time'
import { IUser } from '@/interfaces/user'
import { USER_NAME } from '@/store/user'
import { namespace } from 'vuex-class'
import { customAlphabet, nanoid } from 'nanoid'
import qs from 'qs'
// const chance = require('chance')

const userModule = namespace(USER_NAME)
@Options({
  components: {
  }
})
export default class WechatLogin extends Vue {
  public msg!: string;
  public img!: string;
  @userModule.Getter('userInfo') userInfo!: IUser;

  @catchError()
  created (): void {
    // 请求数据
  }

  @time()
  mounted (): void {
    // 获取唯一id,硬件计算，可以集群不一样
    const nanoid = customAlphabet('1234567890abcdefghijklmn', 8)
    const obj = qs.parse('a=c')
    const ob2 = qs.parse('foo?a=c&foo=1')

    const str = qs.stringify({ foo: 1, bar: 2 })
  }
}

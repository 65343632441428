
import { Options, Vue } from 'vue-class-component'
// import sliderModel from '@/views/Login/components/sliderModel.cmp.vue'
// import { message } from 'ant-design-vue'
import AccountLogin from '@/views/Login/components/AccountLogin.vue'
import PhoneLogin from '@/views/Login/components/PhoneLogin.vue'
import WechatLogin from '@/views/Login/components/WechatLogin.vue'
import { LOGIN_WAY } from '@/views/Login/config'
import { getKeyTitle } from '@/helpers/platform.helper'

const QR_CODE_LOGIN = 1
// import { watch } from 'vue'
// interface ITodoItem{
//   name:string,
//   id:number;
//   isDone:boolean;
// }

@Options({
  components: {
    AccountLogin,
    PhoneLogin,
    WechatLogin
    // sliderModel
  }
})
export default class LoginView extends Vue {
  // data public 对外挂载ui,private 内部使用
  // public activeKey= '2';
  // public smsCode= '';
  public keyTitle = getKeyTitle();
  public LOGIN_WAY = LOGIN_WAY;
  public typeShow = LOGIN_WAY.PHONE // 登录类型
  // public username='kevin' // 用户名
  // public password='123456';

  // public showHides=false // 是否显示验证图片
  // public AccountNumber=''
  // public screenHeight=window.innerHeight
  // public numberObj:IKey={ tong: false, top: 0, range: 0 } // 验证返回数据
  // public Numb=60 // 定时时间
  // public mark=true
  // public img=''
  // public codeId=''
  // public codeItem=0
  // public isPasswordValid=false // 密码格式校验标志位

  created (): void {
    this.checkAutoLogin()
  }

  checkAutoLogin () {
    const auto = this.$route.query.auto
    if (auto === '1') {
      this.typeShow = LOGIN_WAY.ACCOUNT
    }
  }

  mounted (): void {
    // 渲染数据
  }
}

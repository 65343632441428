import https from '@/https/http'
import { IRes, RequestDto } from '@/interfaces/common/api'
import { IPanorama, PanoInfoRes } from '@/interfaces/pano.interface'

export function getPanoramaList (params:RequestDto) { // 全景加载
  return https<any, IRes<IPanorama[]>>({ url: '/api/panorama/list', method: 'GET', params })
}

export function getNearPanoramaList (params:RequestDto) { // 全景加载
  return https<any, IRes<IPanorama[]>>({ url: '/api/panorama/near', method: 'GET', params })
}

/**
 * @description 获取全景点详情
 * @author neohua87@gmail.com
 * @date 27/06/2023
 * @export
 * @param {number} panoId
 * @return {*}
 */
export function getPanoInfo (panoId:number) { // 获取全景点范围内的 报告单
  return https < any, IRes<PanoInfoRes>>({
    url: '/api/panorama/info',
    method: 'GET',
    params: {
      panorama_id: panoId
      // type: 'report'
    }
  })
}
//   export function login (data:ILoginDto) {
//     return https<ILoginDto, IRes<Pick<IUserBase, 'auth'|'access_token'>>>({ url: '/api/auth/login', method: 'POST', data })
//   }


import { Options, Vue } from 'vue-class-component'
import { isWjUm, getKeyTitle } from '@/helpers/platform.helper'
import { Watch } from 'vue-property-decorator'
import log from '@/decorators/log'
import catchError from '@/decorators/catchError'
import time from '@/decorators/time'
import { IUser, UserInfo } from '@/interfaces/user'
import { namespace } from 'vuex-class'
import { USER_NAME } from '@/store/user'
// import { userStore } from '@/store'
//  const userModule = getModule(UserModule, store)
const userStore = namespace(USER_NAME)

@Options({
  components: {
  }
})
export default class MainHeader extends Vue {
    @userStore.Getter('userInfo') userInfo!: UserInfo;
    public isWjUm = isWjUm();
    public keyTitle = getKeyTitle();
    @log
    @catchError()
    created (): void {
    // 请求数据
    }

    @time()
    mounted (): void {
      // 渲染数据
    }
}

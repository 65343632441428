import { IsMobilePhone, isStrongPassword } from '@/validateRules'
import { Validate, IsNotEmpty, MinLength } from 'class-validator'

// abstract class BaseForm {
//     initData!:Record<string, any>;
//     abstract reset ():void;
//     constructor (data:Record<string, any>) {
//       this.initData = data
//     }
// }
// interface IFormProps{
//   [p:string]:any
// }
abstract class BaseForm {
  // private initData!:Record<string, any>;

  // // 记录初始值
  // constructor (data:Record<string, any>) {
  //   this.initData = data
  // }

  // 恢复初始值
  public abstract reset():void
  // public reset () {
  //   for (const key in this.initData) {
  //     this[key] = this.initData[key]
  //   }
  // }
}

interface ILoginForm{
  phone:string;
  smsCode:string;
  name:string;
  password: string;
  new_password: string;
}

// type IKey= keyof ILoginForm;

export class LoginForm extends BaseForm {
  @Validate(IsMobilePhone, {
    message: '手机号码格式有误',
    groups: ['sms', 'phoneLogin']
  })
  phone!: string;

  @MinLength(6, {
    message: '请输入6位数字验证码',
    groups: ['phoneLogin']
  })
  smsCode!: string;

  @IsNotEmpty({
    message: '用户名不能为空',
    groups: ['accountLogin']
  })
  name!: string;

  @Validate(isStrongPassword, {
    message: '密码强度过低,请设置大于8位，且至少含有1个小写字母、1个大写字母和1个"@!#$_"特殊字符',
    groups: ['accountLogin']
  })
  password!: string;

  @Validate(isStrongPassword, {
    message: '新密码必须大于8位，且至少含有1个小写字母、1个大写字母和1个"@!#$_"特殊字符',
    groups: ['updatePwd']
  })
  new_password!: string;

  // formData!:Partial<Omit<LoginForm, 'formData' | 'initData'>>;
  initData:Partial<Omit<ILoginForm, 'initData'>>={};

  constructor (data:Partial<Omit<ILoginForm, 'initData'>>) {
    super()

    this.initData = { ...data }
    data.phone && (this.phone = data.phone)
    data.password && (this.password = data.password)
    data.new_password && (this.new_password = data.new_password)
    data.name && (this.name = data.name)
    data.smsCode && (this.smsCode = data.smsCode)

    // for (const key in data) {
    //   this[key] = data[key]
    // }
  }

  public reset (): void {
    // const keys = Object.keys(this.initData)
    // keys.forEach((key) => {
    //   if (.includes(key)) {
    //     this[key] = this.initData[key]

    //   }
    // })
    // const keys = ['password', 'name', 'phone', 'smsCode']
    // keys.forEach((key) => {
    //   if (this.initData[key]) {
    //     this[key] = this.initData[key]
    //   }
    // })

    // for (const key in this.initData) {
    //   this[key] = this.initData[key]
    // }
    // 暂时没解决动态获取key的ts编译问题
    this.phone = this.initData.phone ?? ''
    this.name = this.initData.name ?? ''
    this.password = this.initData.password ?? ''
    this.new_password = this.initData.new_password ?? ''
    this.smsCode = this.initData.smsCode ?? ''
  }

  // setFormData (data:Partial<LoginForm>) {
  //   for (const key in data) {
  //     this[key] = data[key]
  //   }
  //   this.formData = data
  // }

  // setInitFormData (data:Partial<LoginForm>) {
  //   for (const key in data) {
  //     this[key] = data[key]
  //   }
  //   this.initData = data
  //   return this
  // }

  // getFormData<T> ():T {
  //   return this.formData as T
  // }

  // reset () {
  //   for (const key in this.initData) {
  //     this[key] = this.initData[key]
  //   }
  // }
}

import https from '@/https/http'
import { IRes } from '@/interfaces/common/api'
import { SatelliteInfo, SatelliteItem, StageInfo } from '@/interfaces/satellite.inteface'

export function getSatelliteList (params:any) { // 一张图卫片列表
  return https< any, IRes<SatelliteItem[]>>({ url: '/api/satellite-polygon/list', method: 'GET', params })
}

export function getSatelliteBindingList (params: any) { // 一张图卫片已绑定数据列表
  return https< any, IRes<any[]>>({ url: '/api/satellite-polygon/bindingList', method: 'GET', params })
}

export function doSatelliteBinding (data: any) { // 卫片绑定报告单或全景点
  return https< any, IRes<any[]>>({ url: '/api/satellite-polygon/polygonBind', method: 'POST', data })
}

export function doSatelliteUnBinding (data: any) { // 卫片解绑报告单或全景点
  return https< any, IRes<any[]>>({ url: '/api/satellite-polygon/polygonUnbound', method: 'POST', data })
}

export function satelliteSetDefault (data: any) { // 设置卫片默认展示的报告单或全景
  return https< any, IRes<any[]>>({ url: '/api/satellite-polygon/polygonSet', method: 'POST', data })
}

export function getSatelliteInfo (params:any) { // 一张图卫片详情
  return https< any, IRes<SatelliteInfo>>({ url: '/api/satellite-polygon/info', method: 'GET', params })
}

export function getSatelliteStage () { // 一张图卫片期数
  return https< any, IRes<StageInfo[]>>({ url: '/api/land-polygon/objectStage?object=satellite_land', method: 'GET' })
}

export function getReportSatelliteList (report_id:number) {
  return https< any, IRes<SatelliteInfo[]>>({ url: '/api/satellite-polygon/report', method: 'GET', params: { report_id } })
}

import https from '@/https/http'
import { IRes } from '@/interfaces/common/api'
import { FileInfo, FileItem } from '@/interfaces/oss.interface'

export function getOssFileList (params:any) { // 附件列表
  return https< any, any>({ url: '/api/oss/list', method: 'GET', params })
}

export function getOssFileInfo (params:any) { // 附件详情
  return https< any, IRes<FileInfo>>({ url: '/api/oss/info', method: 'GET', params })
}

export function removeOssFile (data:any) { // 删除附件
  return https < any, any >({ url: '/api/oss/delete', method: 'POST', data })
}

export function updateOssFile (data:any) { // 重命名附件
  return https < any, any >({ url: '/api/oss/update', method: 'POST', data })
}

export function saveOssFile (data:any) { // 存储附件
  return https < any, any >({ url: '/api/oss/create', method: 'POST', data })
}

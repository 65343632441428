import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, { locale: _ctx.zhCN }, {
    default: _withCtx(() => [
      (_ctx.isShowNav)
        ? (_openBlock(), _createBlock(_component_MainHeader, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view)
    ]),
    _: 1
  }, 8, ["locale"]))
}
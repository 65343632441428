import { INetGrider } from '@/interfaces/user/netgrid'
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import * as R from 'ramda'

export const NETGRID_NAME = 'netgrid'

@Module({ namespaced: true, name: NETGRID_NAME })
export default class NetGridModule extends VuexModule {
  public count=0
  public list:INetGrider[]= [
    {
      id: 1,
      is_stress: 1,
      region_ids: '1943,3733,3770',
      role: '村级田长',
      responsible_area: '古田村01，古田村02，古田村03，古田村04，古田村05',
      frequency: '每月一次',
      name: '刘荣',
      phone: '18807511081',
      area: 6703
    },
    {
      id: 2,
      is_stress: 1,
      region_ids: '1943,3733,3770',
      role: '网格田长',
      responsible_area: '古田村01',
      frequency: '每周一次',
      name: '张朝冲',
      phone: '13719798352',
      area: 2493.9
    },
    {
      id: 3,
      is_stress: 2,
      region_ids: '1943,3733,3770',
      role: '网格田长',
      responsible_area: '古田村02',
      frequency: '每周一次',
      name: '谢俊兰',
      phone: '15363291767',
      area: 932.7
    },
    {
      id: 4,
      is_stress: 2,
      region_ids: '1943,3733,3770',
      role: '网格田长',
      responsible_area: '古田村03',
      frequency: '每周一次',
      name: '刘光红',
      phone: '18127389359',
      area: 1021.3

    },
    {
      id: 5,
      is_stress: 2,
      region_ids: '1943,3733,3770',
      role: '网格田长',
      responsible_area: '古田村04',
      frequency: '每周一次',
      name: '刘红兵',
      phone: '13268141820',
      area: 1186.4

    },
    {
      id: 6,
      is_stress: 2,
      region_ids: '1943,3733,3770',
      role: '网格田长',
      responsible_area: '古田村05',
      frequency: '每周一次',
      name: '周群兰',
      phone: '15820117478',
      area: 1068.7
    },
    {
      id: 7,
      is_stress: 1,
      region_ids: '1943,3733,3776',
      role: '村级田长',
      responsible_area: '长迳村01，长迳村02，长迳村03，长迳村04，长迳村05',
      frequency: '每月一次',
      name: '刘光楠',
      phone: '1892441970',
      area: 9718.9
    },
    {
      id: 8,
      is_stress: 2,
      region_ids: '1943,3733,3776',
      role: '网格田长',
      responsible_area: '长迳村01',
      frequency: '每周一次',
      name: '刘传军',
      phone: '13377510155',
      area: 1146.3
    },
    {
      id: 9,
      is_stress: 2,
      region_ids: '1943,3733,3776',
      role: '网格田长',
      responsible_area: '长迳村02',
      frequency: '每周一次',
      name: '刘开利',
      phone: '13829720867',
      area: 1918.5
    },
    {
      id: 10,
      is_stress: 2,
      region_ids: '1943,3733,3776',
      role: '网格田长',
      responsible_area: '长迳村03',
      frequency: '每周一次',
      name: '孔智祥',
      phone: '13680051218',
      area: 3794.4
    },
    {
      id: 11,
      is_stress: 2,
      region_ids: '1943,3733,3776',
      role: '网格田长',
      responsible_area: '长迳村04',
      frequency: '每周一次',
      name: '李小兵',
      phone: '13719706945',
      area: 1689.2
    },
    {
      id: 12,
      is_stress: 2,
      region_ids: '1943,3733,3776',
      role: '网格田长',
      responsible_area: '长迳村05',
      frequency: '每周一次',
      name: '聂春珍',
      phone: '13680078258',
      area: 1170.5
    }
  ]

  public curId= 0

  get stressList () {
    // return this.list.filter(item => item.is_stress === 1)
    const stressFilter = (item:INetGrider) => R.equals(item.is_stress, 2)
    return R.filter(stressFilter)(this.list)
  }

  get unStressList () {
    // return this.list.filter(item => item.is_stress === 1)
    const stressFilter = (item:INetGrider) => R.equals(item.is_stress, 1)
    return R.filter(stressFilter)(this.list)
  }

  @Mutation
  increment (delta:number) {
    this.count += delta
  }

  @Action({ commit: 'increment' })
  decr5 () {
    return 5
  }
}

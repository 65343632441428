import { AnalysisGisItem, FirstRow, ResultItem, ResultRow } from '@/interfaces/analyse.interface'
import { PartialByKeys } from '@/interfaces/common'
import { Point } from '@/interfaces/common/geo'
import { GisItem } from '@/interfaces/gis.inteface'
import { IUser, IUserBase } from '@/interfaces/user'
import { formatArea, formatMuArea, formatNumberWithCommas, w842gd } from '@/utils/formatter'
import { Position } from '@turf/turf'
import { Geometry } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Module, VuexModule, Mutation, Action, config } from 'vuex-module-decorators'

// Set rawError to true by default on all @Action decorators
config.rawError = true

export const ANALYSE_NAME = 'analyse'

@Module({ namespaced: true, name: ANALYSE_NAME })
// export default class CounterModule extends VuexModule implements CounterInterface {
export default class AnalyseModule extends VuexModule {
    // 待分析的图斑路径
    private _polyPath:Position[]=[] // 84坐标
    // private _amapPath:Position[]=[]
    // private _olPath:Position[]=[]
    // 图斑名称
    private _polyName='';
    // 待分析的图层
    private _layers:GisItem[]=[]
    // 分析结果
    private _analysisLayer?:AnalysisGisItem;
    // 渲染在ol上的layers
    private _intersectedLayerList:VectorLayer<VectorSource<Geometry>>[]=[]
    // 保存勾选时候的结果用于打印时候筛选
    private _checkResultIds:string[]=[]

    // 当前500一个单位的比例尺
    private _scaleUnit=1
    private _scale=1;
    // 选择区域后，cql_filter会筛选wms图层
    private _regionIds:number[]=[];

    get printFirstRow () {
      if (!this._analysisLayer) {
        return { label: '', values: [0, 0] }
      }
      const totalArea = this._analysisLayer.checkboxList.reduce((ret, b) => {
        if (this._checkResultIds.includes(b.value)) {
          ret += b.area
        }
        return ret
      }, 0)
      return { label: '总面积', values: [formatArea(totalArea), formatMuArea(totalArea)] }
    }

    get printResultList () {
      if (!this._analysisLayer) {
        return []
      }
      return this._analysisLayer.resultList.filter((item) => {
        return this._checkResultIds.includes(item.value)
      })
    }

    get scaleUnit () {
      return this._scaleUnit
    }

    get regionIds () {
      return this._regionIds
    }

    get scale () {
      return this._scale
    }

    get scaleLabel () {
      return formatNumberWithCommas(this._scale)
    }

    get intersectedLayerList () {
      return this._intersectedLayerList
    }

    get polyName () {
      return this._polyName
    }

    get amapPath () {
      return this._polyPath.map((point) => w842gd(point as Point))
    }

    get olPath () {
      return this._polyPath
    }

    get analysisLayer () {
      return this._analysisLayer
    }

    get layers () {
      return this._layers
    }

    @Mutation
    setCheckResultIds (checkResultIds:string[]) {
      this._checkResultIds = checkResultIds
    }

    @Mutation
    setRegionIds (regionIds:number[]) {
      this._regionIds = regionIds
    }

    @Mutation
    setScale (scale:number) {
      this._scale = scale
    }

      @Mutation
    setScaleUnit (scaleUnit:number) {
      this._scaleUnit = scaleUnit
    }

      @Mutation
      setIntersectedLayerList (intersectedLayerList:VectorLayer<VectorSource<Geometry>>[]) {
        this._intersectedLayerList = intersectedLayerList
      }

  @Mutation
      setPolyName (polyName:string) {
        this._polyName = polyName
      }

  @Mutation
  setAnalysisLayer (analysisLayer:AnalysisGisItem) {
    this._analysisLayer = analysisLayer
  }

  @Mutation
  resetAnalysisLayer () {
    this._analysisLayer = undefined
  }

  @Mutation
  setPolyPath (path:Position[]) {
    this._polyPath = path
  }

  @Mutation
  addLayer (layer:GisItem) {
    const foundIndex = this._layers.findIndex(item => item.id === layer.id)
    if (foundIndex > -1) {
      this._layers.splice(foundIndex, 1)
    }
    this._layers.push(layer)
  }

  @Mutation
  removeLayer (layer:GisItem) {
    const foundIndex = this._layers.findIndex(item => item.id === layer.id)
    if (foundIndex > -1) {
      this._layers.splice(foundIndex, 1)
    }
  }

  @Mutation
  resetLayers () {
    this._layers = []
  }

   @Mutation
  resetVars (isCleanLayers = true) {
    this._intersectedLayerList = []
    this._analysisLayer = undefined
    if (isCleanLayers) {
      this._layers = []
    }
    this._polyPath = []
    this._polyName = ''
  }

  // 分析放在这action
  @Action({})
   async doAnalyseOnOl (foo:string) {
     // todo
     return { foo }
   }
}

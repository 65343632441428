import https from '@/https/http'
import { IListRes, IRes, RequestDto } from '@/interfaces/common/api'
import { OreDetailInfo, OreItem, OreMineItem, RecordItem } from '@/interfaces/ore.interface'

export function getOreList (params:RequestDto) { // 一张图矿区列表
  return https< any, IListRes<OreItem[]>>({ url: '/api/ore-pipe/list', method: 'GET', params })
}

export function getOreOptionList (params:RequestDto) { // 获取矿种、开采方式列表
  return https< any, IRes<any[]>>({ url: '/api/ore-pipe/optionList', method: 'GET', params })
}

export function getOreStages () { // 获取所有越界期数
  return https< any, IRes<any[]>>({ url: '/api/ore-pipe/overstepOption', method: 'GET' })
}

export function getOreRecordList (params:RequestDto) { // 获取矿区监控列表
  return https< any, IRes<RecordItem[]>>({ url: '/api/ore-pipe/records', method: 'GET', params })
}

export function getOreInfo (params:RequestDto) { // 一张图矿区列表
  return https< any, IRes<OreDetailInfo>>({ url: '/api/ore-pipe/info', method: 'GET', params })
}

export function getOreMineList (params:RequestDto) { // 一张图矿区列表
  return https< any, IRes<OreMineItem[]>>({ url: '/api/oremine/list', method: 'GET', params })
}

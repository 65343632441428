import { createApp } from 'vue'
// import VueCesium from 'vue-cesium'
import App from './App.vue'
import Antd from 'ant-design-vue'
// import 'ant-design-vue/dist/antd.min.css'
import 'ant-design-vue/dist/antd.dark.less'
import router from './router'
import store from './store'
import 'vue-cesium/dist/index.css'
// import VueCesium from 'vue-cesium'
// import Axios from '@/https/http'
import '@amap/amap-jsapi-types'

const app = createApp(App)
// app.use(VueCesium, {
//   cesiumPath: '/work/lib/Cesium/Cesium.js'
// })
app.use(Antd).use(store).use(router).mount('#app')

// const access_token = sessionStorage.getItem('access_token')

// if (access_token) {
//   Axios.defaults.headers.common.Authorization = 'Bearer ' + access_token
// }

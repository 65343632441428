import * as XLSX from 'xlsx'

export interface XlsxHeader{
   title: string; key: string
}

interface ExportExcelOptions {
  data: any[];
  name: string;
  headers: XlsxHeader[];
  sheetName: string;
}

export const exportExcel = (options: ExportExcelOptions): void => {
  const { data, name, headers, sheetName } = options

  // 处理headers，提取title用于Excel表头
  const headerTitles = headers.map(header => header.title)

  // 将数据转为二维数组
  const dataArray = data.map(item => headers.map(header => item[header.key]))

  // 创建工作簿
  const workbook = XLSX.utils.book_new()

  // 创建工作表
  const worksheet = XLSX.utils.aoa_to_sheet([headerTitles, ...dataArray])
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName)

  // 将工作簿写入文件
  XLSX.writeFile(workbook, `${name}.xlsx`)
}

export function readJsonFile (path:string) {
  return new Promise((resolve, reject) => {
    fetch(path)
      .then(response => response.json())
      .then(data => {
      // 处理 JSON 数据
        resolve(data)
      })
      .catch(error => reject(error))
  })
}

import { Module, VuexModule, Mutation, config, Action } from 'vuex-module-decorators'
import { GisItem } from '@/interfaces/gis.inteface'
import * as api from '@/https/api'
import { SatelliteInfo, SatelliteItem } from '@/interfaces/satellite.inteface'
import log from '@/decorators/log'

config.rawError = true

export const SATELLITE_NAME = 'satellite'

export interface GetItemByNameFn {
  (name: string): GisItem | null;
}

@Module({ namespaced: true, name: SATELLITE_NAME })
export default class SatelliteModule extends VuexModule {
  public _satelliteList: SatelliteItem[] = [];
  public _satelliteInfo!:SatelliteInfo

  get satelliteList () {
    return this._satelliteList
  }

  get satelliteInfo () {
    return this._satelliteInfo
  }

  @Mutation
  setSatelliteList (satelliteList:SatelliteItem[]) {
    this._satelliteList = satelliteList
  }

  @Mutation
  setSatelliteInfo (info:SatelliteInfo) {
    this._satelliteInfo = info
  }

  @Action({})
  async fetchSatelliteList (params: any) {
    const { data } = await api.getSatelliteList(params)
    this.setSatelliteList(data)
  }

  @Action({})
  async fetchSatelliteInfo (satellite_id: number) {
    const { data } = await api.getSatelliteInfo({ id: satellite_id })
    this.setSatelliteInfo(data)
    return data
  }
}

import { Module, VuexModule, Mutation, config } from 'vuex-module-decorators'
import * as ol from 'ol'
import { DescTypeItem, FenceItem, GisItem, LandTypeItem, WmsMap } from '@/interfaces/gis.inteface'
import { delEmptyKeys } from '@/utils/processObj'
import log from '@/decorators/log'
import { ReportEventsItem, ReportStatusItem, ViolationTypeItem } from '@/interfaces/report.interface'
import { DestroyAmap2 } from '@/helpers/debug.helper'
import { RegionMultiPoly } from '@/interfaces/common/geo'
import * as turf from '@turf/turf'

// Set rawError to true by default on all @Action decorators
config.rawError = true

// export interface CounterInterface{
//     count:number;
//     increment:(delta:number)=>void,
//     decrement:(delta:number)=>void,
//     incr5:()=>void,
//     decr5:()=>void,
// }
export const MAP_NAME = 'map'

export enum MapName {
  ELE,
  SATE,
  OL
}

export enum MapType {
  AMAP,
  OL,
}

export interface GetItemByNameFn {
  (name: string): GisItem | null;
}

export interface DefaultLayer {
  name: string;
  id: number;
}

// @Module({ name: 'counter', dynamic: true, namespaced: true, store: store })
// @Module({ name: 'counter', namespaced: true, dynamic: true, store: store })
// @Module({ generateMutationSetters: true })
@Module({ namespaced: true, name: MAP_NAME })
// export default class CounterModule extends VuexModule implements CounterInterface {
export default class MapModule extends VuexModule {
  private _mapName: MapName = MapName.ELE;
  private _amap?: AMap.Map;
  private _amapS?: AMap.Map;
  // private _olMap!: any;
  private _olMapS?: ol.Map;
  /* 报告单 */
  public reportStatusList: ReportStatusItem[] = []
  public reportEventsList: ReportEventsItem[] = []
  public violationTypeList: ViolationTypeItem[] = []
  public descItemList: DescTypeItem[] = []
  /* gis服务 */
  private gisItemList: GisItem[] = []
  public landTypeOfTwoList: LandTypeItem[] = []
  public landTypeOfThreeList: LandTypeItem[] = []
  public landTypeOfMonitorList: LandTypeItem[] = []
  public landTypeOfBuildingList: LandTypeItem[] = []
  private _isMeasuring = false;

  private _defaultLayer: DefaultLayer = {
    name: '',
    id: 0
  }

  get defaultLayerName () {
    return this._defaultLayer.name
  }

  get defaultLayerId () {
    return this._defaultLayer.id
  }

  get isMeasuring () {
    return this._isMeasuring
  }

  get shownGisList () {
    return this.gisItemList.filter((item) => item.is_show !== 2)
  }

  get mapName () {
    return this._mapName
  }

  get mapType () {
    return this._mapName === MapName.OL ? MapType.OL : MapType.AMAP
  }

  get amapS () {
    return this._amapS
  }

  get olMapS () {
    return this._olMapS
  }

  get amap () {
    return this._amap
  }

  get reportStatus () {
    return this.reportStatusList
  }

  get reportEvents () {
    return this.reportEventsList
  }

  get violationType () {
    return this.violationTypeList
  }

  @Mutation
  setDefaultLayer (defaultLayer: DefaultLayer) {
    this._defaultLayer = defaultLayer
  }

  @Mutation
  setIsMeasuring (isMeasuring: boolean) {
    this._isMeasuring = isMeasuring
  }

  @Mutation
  setViolationType (types: ViolationTypeItem[]) {
    this.violationTypeList = types
  }

  @Mutation
  setReportStatus (status: ReportStatusItem[]) {
    this.reportStatusList = status
  }

  @Mutation
  setReportEvents (events: ReportEventsItem[]) {
    this.reportEventsList = events
  }

  get getItemByName (): GetItemByNameFn {
    return (name: string) => {
      return this.gisItemList.find(item => item.name === name) || null
    }
  }

  @Mutation
  resetGisLayers () {
    this.gisItemList = []
    this.landTypeOfThreeList = []
    this.landTypeOfTwoList = []
    this.landTypeOfMonitorList = []
    this.landTypeOfBuildingList = []
  }

  @Mutation
  setGisItemList (gisItemList: GisItem[]) {
    this.gisItemList = gisItemList
  }

  // 三调类型列表
  get landTypeOfThree () {
    return this.landTypeOfThreeList
  }

  // 二调类型列表
  get landTypeOfTwo () {
    return this.landTypeOfTwoList
  }

  // 常态化监测类型列表
  get landTypeOfMonitor () {
    return this.landTypeOfMonitorList
  }

  get landTypeBuilding () {
    return this.landTypeOfBuildingList
  }

  @Mutation
  /* 设置土地类型树的初始数据 */
  setLandTypeList (landTypeList: LandTypeItem[]) {
    // 三调土地类型
    this.landTypeOfThreeList = landTypeList.filter((item) => item.batch === 3 && item.mod === 1)
    // 二调土地类型
    this.landTypeOfTwoList = landTypeList.filter((item) => item.batch === 2 && item.mod === 1)
    // 常态化监测类型
    this.landTypeOfMonitorList = landTypeList.filter((item) => item.mod === 2)
  }

  @Mutation
  setLandTypeBuildingList (landTypeList: LandTypeItem[]) {
    // 别墅信息的7个子类
    this.landTypeOfBuildingList = landTypeList
  }

  // 变化类型列表
  get descTypeList () {
    return this.descItemList
  }

  @Mutation
  setDescTypeList (descItemList: DescTypeItem[]) {
    this.descItemList = descItemList
  }

  @Mutation
  setMapName (mapName: MapName) {
    this._mapName = mapName
  }

  @Mutation
  setAMap (map: AMap.Map) {
    this._amap = map
  }

  @Mutation
  setAMapS (map: AMap.Map) {
    this._amapS = map
  }

  @Mutation
  resetAMapS () {
    this._amapS?.clearEvents('click')
    this._amapS?.clearMap()
    DestroyAmap2('amaps-container')
    this._amapS?.destroy()
    this._amapS = undefined

    // this._amapS = null
  }

  @Mutation
  resetAMap () {
    this._amap?.clearEvents('click')
    this._amap?.clearEvents('mouseover')
    this._amap?.clearEvents('mousewheel')
    this._amap?.clearMap()
    DestroyAmap2('amap-container')
    this._amap?.destroy()
    this._amap = undefined
    // this._amapS = null
  }

  @Mutation
  setOlMapS (map: ol.Map) {
    this._olMapS = map
  }

  @Mutation
  resetOlMapS () {
    this._olMapS?.setTarget(undefined)
    this._olMapS?.dispose()
    this._olMapS = undefined
  }
}

import https from '@/https/http'
import { IRes } from '@/interfaces/common/api'
import { OrthItem } from '@/interfaces/orth.interfacel'

// 正射影像
interface OrthListDto{
    name?:string;
    region_id?:number;
    year?:number;
}

interface OrthSimpleDto{
  name?:string;
  region_id?:number;
  select?:string
}
export function polygonOrthList (params: OrthListDto) {
  return https <OrthListDto, IRes<OrthItem[]>>({ url: '/api/orthophoto-image/list', method: 'GET', params })
}

export function getSimpleOrthList (params: OrthSimpleDto) {
  return https <OrthSimpleDto, IRes<OrthItem[]>>({ url: '/api/orthophoto-image/list/simple', method: 'GET', params })
}

// 根据飞行图斑ID获取到正射详情，同一个飞行图斑有多期正射
export function getOrthInfo (orthId:number) {
  return https < number, IRes<OrthItem[]>>({ url: '/api/orthophoto-image/listByPolygon', method: 'GET', params: { flight_polygon: orthId } })
}


import { Options, Vue } from 'vue-class-component'
import log from '@/decorators/log'
import { Watch } from 'vue-property-decorator'
import { RouteRecordRaw } from 'vue-router'
import MainHeader from '@/components/MainHeader.vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('cn')
@Options({
  components: {
    MainHeader
  }
})
export default class App extends Vue {
  public isShowNav=true;
  public zhCN=zhCN

  // @log
  @Watch('$route')
  onRouteChange (route:RouteRecordRaw) {
    const { path } = route
    // 登录页和数据中心不显示顶部导航
    const hideRouteList = ['login', 'datacenter']
    const ret = hideRouteList.filter((item) => {
      return path.includes(item)
    })
    this.isShowNav = !ret.length
  }
}

export default [
  {
    id: 1,
    name: '自然资源基础数据',
    icon: '/work/images/icons/menu_base.png',
    iconChecked: '/work/images/icons/menu_base_checked.png',
    showPanel: true

  },
  {
    id: 2,
    name: '时空间信息平台',
    icon: '/work/images/icons/menu_timespace.png',
    iconChecked: '/work/images/icons/menu_timespace_checked.png',
    showPanel: true
  },
  {
    id: 3,
    name: '任务详情',
    icon: '/work/images/icons/menu_task.png',
    iconChecked: '/work/images/icons/menu_task_checked.png',
    showPanel: true
  },
  {
    id: 4,
    name: '卫片核查',
    icon: '/work/images/icons/menu_store.png',
    iconChecked: '/work/images/icons/menu_store_checked.png',
    showPanel: true
  },

  {
    id: 8,
    name: '地质灾害',
    icon: '/work/images/icons/menu_project.png',
    iconChecked: '/work/images/icons/menu_project_checked.png',
    showPanel: true
  }

]

export default [
  {
    id: 1,
    name: '城市基础数据',
    icon: '/work/images/icons/menu_base.png',
    iconChecked: '/work/images/icons/menu_base_checked.png',
    showPanel: true

  },
  {
    id: 2,
    name: '时空间信息平台',
    icon: '/work/images/icons/menu_timespace.png',
    iconChecked: '/work/images/icons/menu_timespace_checked.png',
    showPanel: true
  },
  {
    id: 3,
    name: '变化详情',
    icon: '/work/images/icons/menu_task.png',
    iconChecked: '/work/images/icons/menu_task_checked.png',
    showPanel: true
  }
]

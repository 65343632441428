import { Region } from '@/interfaces/region.interface'
import { Module, VuexModule, Mutation, config } from 'vuex-module-decorators'

// Set rawError to true by default on all @Action decorators
config.rawError = true

export const SELECT_NAME = 'select'

@Module({ namespaced: true, name: SELECT_NAME })
export default class SelectModule extends VuexModule {
  private _regionTree!:Region[];
  private _subjectId = 0;
  private _subjectName = '';
  // private _map_s!: any;

  get subjectId ():number {
    return this._subjectId
  }

  get subjectName ():string {
    return this._subjectName
  }

  get regionTree () {
    return this._regionTree
  }

  //  默认展开的项,传递region_id即可展开对应项目
  get regionDefaultExpandedKeys () {
    if (!this._regionTree || !this._regionTree.length) {
      return []
    }
    return this._regionTree.map((item) => item.region_id)
  }

  @Mutation
  setRegionTree (regionTree: Region[]) {
    this._regionTree = regionTree
  }

  @Mutation
  setSubjectId (subjectId: number) {
    this._subjectId = subjectId
  }

  @Mutation
  setSubjectName (subjectName: string) {
    this._subjectName = subjectName
  }
}

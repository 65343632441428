import { PartialByKeys } from '@/interfaces/common'
import { IUser, IUserBase, UserInfo } from '@/interfaces/user'
import { Module, VuexModule, Mutation, Action, config } from 'vuex-module-decorators'

// Set rawError to true by default on all @Action decorators
config.rawError = true

// export interface CounterInterface{
//     count:number;
//     increment:(delta:number)=>void,
//     decrement:(delta:number)=>void,
//     incr5:()=>void,
//     decr5:()=>void,
// }
export const USER_NAME = 'user'
type IUpdateUser =Pick<PartialByKeys<IUserBase, 'name'|'password'>, 'name'|'password'>

// @Module({ name: 'counter', dynamic: true, namespaced: true, store: store })
// @Module({ name: 'counter', namespaced: true, dynamic: true, store: store })
// @Module({ generateMutationSetters: true })
@Module({ namespaced: true, name: USER_NAME })
// export default class CounterModule extends VuexModule implements CounterInterface {
export default class UserModule extends VuexModule {
  private info!: IUser;
  private user!: UserInfo;

  get userInfo () {
    if (this.user) {
      return this.user
    }
    const localUser = sessionStorage.getItem('user')
    if (localUser) {
      return JSON.parse(localUser)
    }
    return null
  }

  get userAuth () {
    if (this.userInfo && this.userInfo.auth_list) {
      return (this.userInfo as UserInfo).auth_list.map((auth) => +auth)
    }
    return []
  }

  // 数据员
  get isDataUser ():boolean {
    return !!this.userAuth.find((auth) => auth === 3)
  }

  get isGlobalUser () {
    return this.info.auth?.find((item) => +item === 123)
  }

  @Mutation
  setUserInfo (data:UserInfo) {
    this.user = data
    sessionStorage.setItem('user', JSON.stringify(data))
  }

  @Mutation
  setBaseUserInfo (data:IUser) {
    this.info = data
    const { access_token, auth } = data
    sessionStorage.setItem('access_token', access_token)
    sessionStorage.setItem('auth', JSON.stringify(auth))
  }

  @Mutation
  setUserRegionId (region_id:number) {
    sessionStorage.setItem('region_id', JSON.stringify(region_id))
  }

  @Mutation
  removeLogoutUser () {
    sessionStorage.removeItem('access_token')
    sessionStorage.removeItem('region_id')
    sessionStorage.removeItem('auth')
  }

  @Mutation
  updateInfo (updateData:IUpdateUser) {
    this.info = {
      ...this.info,
      ...updateData
    }
  }

  // action 'incr' commits mutation 'increment' when done with return value as payload
  // @Action({ commit: 'setBaseUserInfo' })
 @Action({ commit: 'setBaseUserInfo' })
  async login ({ name, code }: IUser&Record<'code', 'string'>) {
    return { name, code }
  }

  // action 'decr' commits mutation 'decrement' when done with return value as payload
  @Action({ commit: 'updateInfo' })
 async updateUserInfo ({ password }:IUpdateUser) {
   const mockApi = ():Promise<string> => {
     // eslint-disable-next-line @typescript-eslint/no-unused-vars
     return new Promise((resolve, reject) => {
       setTimeout(() => {
         resolve('更新成功')
       }, 1000)
     })
   }
   await mockApi()
   return { name, password }
 }
}

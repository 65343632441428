import { DetailMapItem } from '@/interfaces/3d.interfacel'
import { Position } from '@turf/turf'
import { Module, VuexModule, Mutation, config } from 'vuex-module-decorators'

// Set rawError to true by default on all @Action decorators
config.rawError = true

export const CSMAP_NAME = 'csMap'

@Module({ namespaced: true, name: CSMAP_NAME })
// export default class CounterModule extends VuexModule implements CounterInterface {
export default class CsMapModule extends VuexModule {
  // 地图
  // 三维地图的相对路径
  private _path3d!:string;
  //  影响3d模型质量的关键参数
  private _maximumScreenSpaceError=16;
  // 摄像头聚焦图斑时候的高度
  private _camera_height=190;
  // 多边形面高亮区域84坐标
  private _polyPath:Position[]=[]
  // 点击ol聚焦的坐标
 private _focusAt:Position=[]
 // 点击csmap的坐标，用于三维跳转2纬
 private _clickAt:Position=[]
 //  建筑等基础图层的detailmap信息
  private _detailMapList:DetailMapItem[]=[]

  get detailMapList () {
    return this._detailMapList
  }

  get clickAt () {
    return this._clickAt
  }

  get focusAt () {
    return this._focusAt
  }

  get polyPath () {
    return this._polyPath
  }

  get url () {
    return process.env.VUE_APP_PUB_URL + this._path3d
  }

  get maximumScreenSpaceError () {
    return this._maximumScreenSpaceError
  }

  get camera_height () {
    return this._camera_height
  }

  @Mutation
  setDetailMapList (detailMapList:DetailMapItem[]) {
    this._detailMapList = detailMapList
  }

 @Mutation
  setClickPosition (clickAt:Position) {
    this._clickAt = clickAt
  }

@Mutation
 setFocusPosition (focusAt:Position) {
   this._focusAt = focusAt
 }

  @Mutation
setPolyPath (polyPath:Position[]) {
  this._polyPath = polyPath
}

  @Mutation
  setPath3d (path3d:string) {
    this._path3d = path3d
  }

  @Mutation
  setMaximumScreenSpaceError (maximumScreenSpaceError:number) {
    this._maximumScreenSpaceError = maximumScreenSpaceError
  }

  @Mutation
  setCameraHeight (camera_height:number) {
    this._camera_height = camera_height
  }

  @Mutation
  resetVars () {
    this._path3d = ''
  }
}

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import * as R from 'ramda'
import { IMenu } from '@/interfaces/menu'
import { getMenu } from '@/helpers/platform.helper'

export const MENU_NAME = 'menu'

@Module({ namespaced: true, name: MENU_NAME })
export default class MenuModule extends VuexModule {
  public list:IMenu[]=getMenu()

  // 记住刷新前所在的菜单
  public curId=sessionStorage.getItem('menuId')
    ? Number(sessionStorage.getItem('menuId'))
    : this.list.length ? this.list[0].id : 0

  get selectedMenu () {
    const stressFilter = (item:IMenu) => R.equals(item.id, this.curId)
    return R.find(stressFilter)(this.list)
  }

  get isShowPanel () {
    if (!this.list.length) {
      return false
    }
    return R.prop('showPanel')(this.selectedMenu as IMenu)
  }

  // @Mutation
  // initMenu () {
  //   this.list = getMenu()
  // }

  // @Mutation
  // resetMenu () {
  //   this.list = []
  // }

  @Mutation
  activeMenuId (id:number) {
    this.curId = id
    sessionStorage.setItem('menuId', id.toString())
  }

  @Mutation
  togglePanelShow (isShow?:boolean) {
    const itemMap = (item:IMenu) => {
      if (item.id === this.curId) {
        return { ...item, showPanel: isShow || !item.showPanel }
      }
      return item
    }
    this.list = R.map(itemMap)(this.list)
  }
}

import Axios from 'axios'
import router from '@/router'
const axiosInstance = Axios.create({
  timeout: 5000,
  baseURL: process.env.VUE_APP_API_HOST
})

axiosInstance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  config.headers.Authorization = 'Bearer ' + sessionStorage.getItem('access_token')
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

axiosInstance.interceptors.response.use(function (response) {
  // 添加响应拦截器
  if (response.data.code === 0) {
    return response.data
  } else {
    return Promise.reject(new Error(response.data.message))
  }
}, function (error) {
  const status = error.request.status

  if (status === 401) {
    router.replace('/login')
  } else if (status === 400) {
    return Promise.reject(new Error(error.response.data.message))
  } else {
    return Promise.reject(error)
  }
})

export default axiosInstance

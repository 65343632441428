import { Center } from '@/interfaces/common/geo'
import { Krpano, PanoOpt } from '@/interfaces/common/krpano'
import { PanoInfo, PanoSnashot } from '@/interfaces/pano.interface'
import KrpanoConverter from '@skylar/krpano-converter'
import KrpanoTools from '@skylar/krpano-tools'
import { Module, VuexModule, Mutation, config, Action } from 'vuex-module-decorators'
import * as api from '@/https/api'
import dayjs from 'dayjs'
import { w842gd } from '@/utils/formatter'
import { fixFunction, getSnapShotCenter } from '@/helpers/pano.helper'
import { Position } from '@turf/turf'

// Set rawError to true by default on all @Action decorators
config.rawError = true

function initKrConverter (krpanoObj: Krpano, center: Center, offsetAngle: number, height: number) {
  const mKrpanoConverter = new KrpanoConverter({
    krpano: krpanoObj,
    height: height || 150,
    center: w842gd([...center]),
    offsetAngle
  })
  mKrpanoConverter.setFixFunc(fixFunction)

  // 指南针校准
  krpanoObj.set('global_heading', offsetAngle)
  krpanoObj.set('view.hlookat', offsetAngle)

  return mKrpanoConverter
}

export const PANO_NAME = 'pano'

export enum ScreenMode {
  single,
  double,
}
export enum ScreenFullMode {
  standard,
  full,
}

type PanoListener = () => void
@Module({ namespaced: true, name: PANO_NAME })
export default class PanoModule extends VuexModule {
  private _panoSnapshotList: PanoSnashot[] = [];
  private _panoSnapshotNew!: PanoSnashot;
  private _panoInfo!: PanoInfo;
  // 聚焦位置
  private _focusMapAt: Center = [0, 0];
  private _screenMode: ScreenMode = ScreenMode.single;
  private _screenFullMode: ScreenFullMode = ScreenFullMode.standard;
  private _krpanoObjOld?: Krpano
  private _krpanoObjNew?: Krpano
  private _isPanoChange = false;
  // 跨模块通知勾选服务范围
  private _isCheckService = false;
  private _panoOldOpt: PanoOpt = {
    center: [], // 84坐标
    height: 150,
    offsetAngle: 0
  };

  private _panoNewOpt: PanoOpt = {
    center: [], // 84坐标
    height: 150,
    offsetAngle: 0
  };

  private _oldPanoChangeListeners: PanoListener[] = []
  private _newPanoChangeListeners: PanoListener[] = []

  get isCheckService () {
    return this._isCheckService
  }

  get isPanoChange () {
    return this._isPanoChange
  }

  get stageOptions () {
    if (!this._panoSnapshotList) {
      return []
    }
    return this._panoSnapshotList.map((item) => ({ snapshot: item, label: dayjs(item.PanoSnapshot_stage).format('YYYY-MM-DD'), value: item.PanoSnapshot_oss_url }))
  }

  get panoSnapshotNew () {
    return this._panoSnapshotNew
  }

  get panoCenter (): Center {
    if (!this.panoInfo) {
      return [0, 0]
    }
    const { panorama_lat, panorama_lng } = this._panoInfo
    return [+panorama_lng, +panorama_lat]
  }

  get panoInfo () {
    return this._panoInfo
  }

  get panoSnapshotList () {
    return this._panoSnapshotList
  }

  get focusMapAt () {
    return this._focusMapAt
  }

  get krpanoToolNew () {
    if (!this._krpanoObjNew) {
      return null
    }
    return new KrpanoTools(this._krpanoObjNew)
  }

  get krpanoToolOld () {
    if (!this._krpanoObjOld) {
      return null
    }
    return new KrpanoTools(this._krpanoObjOld)
  }

  get krpanoConverterNew () {
    if (!this._krpanoObjNew) {
      return null
    }
    const { center, offsetAngle, height } = this._panoNewOpt
    return initKrConverter(this._krpanoObjNew, center as Center, offsetAngle, height)
  }

  get krpanoConverterOld () {
    if (!this._krpanoObjOld) {
      return null
    }
    const { center, offsetAngle, height } = this._panoOldOpt
    return initKrConverter(this._krpanoObjOld, center as Center, offsetAngle, height)
  }

  get krpanoObjOld () {
    return this._krpanoObjOld
  }

  get krpanoObjNew () {
    return this._krpanoObjNew
  }

  get panoOldOpt () {
    return this._panoOldOpt
  }

  get panoNewOpt () {
    return this._panoNewOpt
  }

  get screenMode () {
    return this._screenMode
  }

  get screenFullMode () {
    return this._screenFullMode
  }

  @Mutation
  addOldPanoListener (listener: PanoListener) {
    if (!this._oldPanoChangeListeners.includes(listener)) {
      this._oldPanoChangeListeners.push(listener)
    }
  }

  @Mutation
  addNewPanoListener (listener: PanoListener) {
    if (!this._newPanoChangeListeners.includes(listener)) {
      this._newPanoChangeListeners.push(listener)
    }
  }

  @Mutation
  setIsCheckService (isCheckService: boolean) {
    this._isCheckService = isCheckService
  }

  @Mutation
  setScreenMode (screenMode: ScreenMode) {
    this._screenMode = screenMode
  }

  @Mutation
  setPanoChange (isPanoChange: boolean) {
    this._isPanoChange = isPanoChange
  }

  @Mutation
  setPanoInfo (panoInfo: PanoInfo) {
    this._panoInfo = { ...panoInfo }
  }

  @Mutation
  setPanoSnapshotNew (panoSnapshotNew: PanoSnashot) {
    this._panoSnapshotNew = { ...panoSnapshotNew }
  }

  @Mutation
  setPanoSnapShotList (panoSnapshotList: PanoSnashot[]) {
    this._panoSnapshotList = [...panoSnapshotList]
  }

  @Mutation
  setFullMode (screenFullMode: ScreenFullMode) {
    this._screenFullMode = screenFullMode
  }

  @Mutation
  resetKrpanoObj () {
    const oldKrapnoId = 'oldPano'
    const newKrapnoId = 'newPano'
    const oldEl = document.getElementById(oldKrapnoId)
    const newEl = document.getElementById(newKrapnoId)

    if (oldEl) {
      this._krpanoObjOld && window.removepano(oldKrapnoId)
      this._krpanoObjOld = undefined
    }
    if (newEl) {
      this._krpanoObjNew && window.removepano(newKrapnoId)
      this._krpanoObjNew = undefined
    }
    this._oldPanoChangeListeners = []
    this._newPanoChangeListeners = []
  }

  @Mutation
  setKrpanoObjOld (krpanoObjOld: Krpano) {
    this._krpanoObjOld = krpanoObjOld
  }

  @Mutation
  setKrpanoObjNew (krpanoObjNew: Krpano) {
    this._krpanoObjNew = krpanoObjNew
  }

  @Mutation
  setPanoOldOpt (panoOldOpt: PanoOpt) {
    this._panoOldOpt = panoOldOpt
  }

  @Mutation
  setPanoNewOpt (panoNewOpt: PanoOpt) {
    this._panoNewOpt = panoNewOpt
  }

  @Mutation
  resetPanos () {
    this._panoOldOpt = {
      center: [],
      height: 150,
      offsetAngle: 0
    }
    this._panoNewOpt = {
      center: [],
      height: 150,
      offsetAngle: 0
    }
  }

  @Action({ commit: 'setFullMode' })
  toggleFullMode () {
    return this._screenFullMode ^ 1
  }

  @Mutation
  setFocusPosition ({ lng, lat }: { lng: number, lat: number }) {
    this._focusMapAt = [lng, lat]
  }

  @Action({})
  async emitOldPanoChange () {
    if (this._oldPanoChangeListeners.length) {
      this._oldPanoChangeListeners.forEach((fn) => {
        fn()
      })
    }
  }

  @Action({})
  async emitNewPanoChange () {
    if (this._newPanoChangeListeners.length) {
      this._newPanoChangeListeners.forEach((fn) => {
        fn()
      })
    }
  }

  @Action({})
  async fetchPanoInfo (panoId: number) {
    const res = await api.getPanoInfo(panoId)
    const { pano_snapshot, ...panorama_info } = res.data
    // 这里要设置好新旧全景快照的height,offsetAngle,pano_snapshot
    // 因为height,offsetAngle是根据pano_snapshot来计算的，可根据stage获取
    this.setPanoChange(true)
    this.setPanoInfo(panorama_info)
    this.setPanoSnapShotList(pano_snapshot)
    if (!pano_snapshot.length) {
      return false
    }
    const panoSnapshot_new = pano_snapshot[0]
    let panoSnapshot_old = pano_snapshot[0]

    if (pano_snapshot.length > 1) {
      panoSnapshot_old = pano_snapshot[1]
    }
    this.setPanoSnapshotNew(panoSnapshot_new)
    // const { panorama_lng, panorama_lat } = panorama_info
    const { PanoSnapshot_height: heightNew, PanoSnapshot_offset_angle: offsetAngleNew } = panoSnapshot_new
    const { PanoSnapshot_height: heightOld, PanoSnapshot_offset_angle: offsetAngleOld } = panoSnapshot_old
    const center_new = getSnapShotCenter(panoSnapshot_new, panorama_info)
    const center_old = getSnapShotCenter(panoSnapshot_old, panorama_info)
    // let center:Center = [+panorama_lng, +panorama_lat]
    // center = w842gd(center)

    this.setPanoNewOpt({ center: center_new, height: heightNew, offsetAngle: offsetAngleNew })
    this.setPanoOldOpt({ center: center_old, height: heightOld, offsetAngle: offsetAngleOld })
  }
}

import https from '@/https/http'
import { IListRes, IRes } from '@/interfaces/common/api'
import { ProjectDetailInfo, ProjectItem } from '@/interfaces/project.inteface'

export function getProjectList (params:any) { // 一张图矿区列表
  return https< any, IListRes<ProjectItem[]>>({ url: '/api/project-supervision/list', method: 'GET', params })
}

export function getProjectInfo (params:any) { // 一张图矿区列表
  return https< any, IRes<ProjectDetailInfo>>({ url: '/api/project-supervision/info', method: 'GET', params })
}

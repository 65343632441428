
import demoMenuList from '@/data/demoMenuList'
import nxMenuList from '@/data/nxMenuList'
import qjMenuList from '@/data/qjMenuList'
import umMenuList from '@/data/umMenuList'
import wjplusMenuList from '@/data/wjplusMenuList'
import { Center } from '@/interfaces/common/geo'
import { IMenu } from '@/interfaces/menu'
import { readJsonFile } from '@/utils/file'

/**
 * @description 判断当前环境，返回菜单列表
 * @returns boolean
 */
export const getMenu = (): IMenu[] => {
  switch (process.env.VUE_APP_NAMESPACE) {
    case 'demo':
      return demoMenuList
    case 'nx':
      return nxMenuList
    case 'wjum':
      return umMenuList
    case 'wjplus':
      return wjplusMenuList
    case 'qj':
      return qjMenuList
    default:
      return demoMenuList
  }
}

/**
 * @description 判断当前环境，返回主页顶部大标题
 * @returns boolean
 */
export const getKeyTitle = (): string => {
  switch (process.env.VUE_APP_NAMESPACE) {
    case 'demo':
      return '自然资源智慧监管平台'
    case 'nx':
      return '南雄市耕地保护“田长制”动态监测系统'
    case 'qj':
      return '曲江区自然资源智慧监管平台'
    case 'wjum':
      return '武江区城市数字底座'
    case 'wjplus':
      return '武江区自然资源智慧监管平台'
    default:
      return '自然资源智慧监管平台'
  }
}

export const getMainRegionId = (): number => {
  switch (process.env.VUE_APP_NAMESPACE) {
    case 'demo':
      // 整个韶关
      return 1933
    case 'nx':
      return 1943
    case 'wjum':
    case 'wjplus':
      return 1934
    case 'qj':
      return 1936
    default:
      return 1933
  }
}

export const isPro = (): boolean => {
  return process.env.VUE_APP_ENV === 'pro'
}

export const getRegionGeoData = async () => {
  switch (process.env.VUE_APP_NAMESPACE) {
    case 'nx':
      return {
        // data: nxGeojsonData,
        data: await readJsonFile('/work/data/nx_m2.json'),
        dx: 0.25, // 地图中心点水平偏移
        dy: 0.5, // 地图中心点垂直偏移
        dz: 0,
        scale: 3, // 形状缩放比例
        center: [114.3554398978827, 25.187346074746802]// 投影坐标到中心点
      }
    case 'wjum':
    case 'wjplus':
      return {
        // data: wjGeojsonData,
        data: await readJsonFile('/work/data/wj_m2.json'),
        dx: 1.75,
        dy: 1,
        dz: 0,
        scale: 3,
        center: [113.585309, 24.797816]
      }
    case 'qj':
      return {
        // data: nxGeojsonData,
        data: await readJsonFile('/work/data/qj_m2.json'),
        dx: 0.25, // 地图中心点水平偏移
        dy: 0.5, // 地图中心点垂直偏移
        dz: 0,
        scale: 3, // 形状缩放比例
        center: [113.5972, 24.8104]// 投影坐标到中心点
      }
    case 'demo':
    default:
      // 整个韶关
      return {
        // data: geojsonData,
        data: await readJsonFile('/work/data/sg_m2.json'),
        dx: -0.5,
        dy: 0.5,
        dz: 0,
        scale: 0.8,
        center: [113.5972, 24.8104]
      }
  }
}

export const getRegionCenter = (): Center => {
  switch (process.env.VUE_APP_NAMESPACE) {
    case 'nx':
      return [114.42547496885061, 25.182822959131148]
    case 'wjum':
      return [113.5104153, 24.79059943]
    case 'wjplus':
      return [113.585309, 24.797816]
    case 'demo':
    default:
      return [113.59762, 24.810879]
  }
}

export const getDefaultZoom = (): number => {
  switch (process.env.VUE_APP_NAMESPACE) {
    case 'nx':
      return 11
    case 'qj':
      return 11
    case 'wjum':
      return 13
    case 'wjplus':
      return 11
    case 'demo':
      return 11
    default:
      return 11
  }
}

export const getZoomRange = (): [number, number] => {
  switch (process.env.VUE_APP_NAMESPACE) {
    case 'demo':
      return [8, 18]
    default:
      return [11, 18]
  }
}

/**
 * @description 判断是否是nx项目
 * @returns boolean
 */
export const isNx = (): boolean => {
  return process.env.VUE_APP_NAMESPACE === 'nx'
}

export const isDemo = (): boolean => {
  return process.env.VUE_APP_NAMESPACE === 'demo'
}

export const isWjUm = (): boolean => {
  return process.env.VUE_APP_NAMESPACE === 'wjum'
}

export const isWjPlus = (): boolean => {
  return process.env.VUE_APP_NAMESPACE === 'wjplus'
}

export const isQj = (): boolean => {
  return process.env.VUE_APP_NAMESPACE === 'qj'
}

export const getPlatform = (): string => {
  return process.env.VUE_APP_NAMESPACE || 'unknown platform'
}

export const getSubRegions = () => {
  return [
    {
      region_id: 3732,
      name: '湖口镇'
    },
    {
      region_id: 3733,
      name: '珠玑镇'
    }
  ]
}

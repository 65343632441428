import https from '@/https/http'
import { AchievementItem } from '@/interfaces/3d.interfacel'
import { IRes } from '@/interfaces/common/api'

// 正射影像
interface AchievementListDto{
    name?:string;
    region_id?:number;
    year?:any;
}
export function polygonAchievementList (params: AchievementListDto) {
  return https <AchievementListDto, IRes<AchievementItem[]>>({ url: '/api/achievement/list', method: 'GET', params })
}

export function getAchievementInfo (achievement_id:number) {
  return https < number, IRes<AchievementItem>>({ url: '/api/achievement/info', method: 'GET', params: { id: achievement_id } })
}

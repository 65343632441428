
import { Options, Vue } from 'vue-class-component'
import catchError from '@/decorators/catchError'
import time from '@/decorators/time'
import UserModule from '@/store/user'
import { LOGIN_WAY } from '@/views/Login/config'
import * as api from '@/https/api'
import { getModule } from 'vuex-module-decorators'
import router from '@/router'
import store from '@/store'
import { LoginForm } from '../LoginForm'
import { validateOrReject } from 'class-validator'
import { message } from 'ant-design-vue'
import { isPro } from '@/helpers/platform.helper'
import skyLoading from '@/decorators/skyLoading'
import skyDebounce from '@/decorators/skyDebounce'

const mUserModule = getModule(UserModule, store)

@Options({
  components: {
  }
})
export default class AccountLogin extends Vue {
  public isPasswordValid = false;
  public resetShow = false // 设置密码弹窗是否显示
  public forgetShow = false // 忘记密码弹窗是否显示
  public forgetPhone = ''
  public forgetCode = ''
  public newPassword = ''
  public confirmPassword = ''
  public loading = false
  public btnTitle = '发送验证码'
  public userId = 0

  public mLoginForm = new LoginForm({
    name: isPro() ? '' : '林林四', // 区级
    // name: '林三三', // 镇级
    password: isPro() ? '' : 'Skylar@2023'
  });

  public updateForm = new LoginForm({
    new_password: ''
    // new_password: 'Skylar@2023'
  });

  handlePhoneInput () {
    // 过滤非数字字符
    this.forgetPhone = this.forgetPhone.replace(/\D/g, '')
  }

  handleSmsCodeInput () {
    this.forgetCode = this.forgetCode.replace(/\D/g, '')
  }

  @catchError()
  created (): void {
    // 请求数据
    this.checkAutoLogin()
  }

  checkAutoLogin () {
    const auto = this.$route.query.auto
    const name = this.$route.query.name as string || '麦麦'
    if (auto === '1') {
      this.mLoginForm.name = name
      this.mLoginForm.password = 'Skylar@2023'
      this.onLoginClick()
    }
  }

  @time()
  mounted (): void {
    // 渲染数据
  }

  @skyDebounce()
  @catchError()
  @skyLoading()
  async onLoginClick () {
    // 自动识别accountLogin组需要校验的username和password
    if (!this.mLoginForm.password || !this.mLoginForm.password.trim()) {
      throw new Error('密码不能为空')
    }
    await validateOrReject(this.mLoginForm, { groups: ['accountLogin'] })
    const postData: any = {
      code: 0,
      type: LOGIN_WAY.ACCOUNT,
      name: this.mLoginForm.name,
      password: this.mLoginForm.password
    }

    const { data: token } = await api.login(postData)
    mUserModule.setBaseUserInfo({ ...token, name: postData.name })
    const { data: userInfo } = await api.getUserInfo()
    mUserModule.setUserRegionId(userInfo.region_id)
    mUserModule.setUserInfo(userInfo)
    this.mLoginForm.reset()
    router.push('/yzt')
  }

  openForgetModel () {
    this.forgetShow = true
  }

  closeSms () {
    this.forgetShow = false
    this.forgetCode = ''
    this.forgetPhone = ''
  }

  closePassword () {
    this.resetShow = false
    this.newPassword = ''
    this.confirmPassword = ''
  }

  async judgeSms () {
    const param = {
      type: 'password',
      code: this.forgetCode,
      mobile: this.forgetPhone
    }
    const data = await api.autoJudgeSms(param)
    if (data.code === 0) {
      message.success('验证成功')
      this.userId = data.data.user_id
      this.forgetShow = false
      this.forgetCode = ''
      this.forgetPhone = ''
      this.resetShow = true
    }
  }

  @catchError()
  async resetPassword () {
    if (this.newPassword !== this.confirmPassword) {
      throw new Error('两次密码不一致')
    }
    if (this.newPassword) {
      this.updateForm.new_password = this.newPassword
      await validateOrReject(this.updateForm, { groups: ['updatePwd'] })
    }
    const postData = {
      id: this.userId,
      new_password: this.newPassword
    }
    const data = await api.resetUserPwd(postData)
    if (data.code === 0) {
      message.success('密码设置成功')
      this.resetShow = false
      this.newPassword = ''
      this.confirmPassword = ''
    }
  }

  @catchError()
  async onSendBtnClick () {
    // 自动识别sms组需要校验的phone
    await validateOrReject(this.forgetPhone, { groups: ['sms'] })
    const param = { mobile: this.forgetPhone, type: 'password' }
    const data = await api.autoSendSms(param)
    message.success(data.message)
  }
}

import { getMainRegionId, getRegionCenter, isDemo } from '@/helpers/platform.helper'

// 南雄中心点 高德坐标
export const gCenter:[number, number] = getRegionCenter()

export const getRegionId = ():number => {
  const regionId = sessionStorage.getItem('region_id')

  return regionId ? +regionId : getMainRegionId()
}

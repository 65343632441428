// 获取当前活动的 WebGL 上下文数量
function getActiveWebGLContextCount () {
  let count = 0

  // 获取 Canvas 元素
  const canvasElements = document.getElementsByTagName('canvas')

  // 遍历 Canvas 元素，检查是否支持 WebGL
  for (const canvas of canvasElements) {
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
    if (gl) {
      count++
    }
  }

  return count
}

// 监听 WebGL 上下文数量的变化
export function watchWebGLContextCount () {
  setInterval(() => {
    const activeContextCount = getActiveWebGLContextCount()
    if (activeContextCount >= 16) {
      console.warn('WARNING: Too many active WebGL contexts. Oldest context will be lost.')
      console.warn('太多激活的webgl对象')
    }
  }, 5000) // 每秒检查一次
}

// 启动监听
// watchWebGLContextCount()

/**
 * @description 解决高德2.0版本的 map.destory的时候没有真正释放内存，和保持webgl context
 * 导致 Too many active WebGL contexts. Oldest context will be lost 然后引发
 * createBuffer error的错误，导致首页地图对象丢失无法交互的原因
 */
export function DestroyAmap2 (containerId:string) {
  const container = document.getElementById(containerId)
  if (!container) {
    return false
  }
  const canvas:HTMLCanvasElement|null = container.querySelector('canvas.amap-layer')
  if (!canvas) {
    return false
  }
  const ctx = canvas.getContext('webgl')
  // canvas.addEventListener('webglcontextlost', (e:any) => {
  //   // do nothing
  // }, false)
  ctx && ctx.getExtension('WEBGL_lose_context')?.loseContext()
}


import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import log from '@/decorators/log'
import catchError from '@/decorators/catchError'
import time from '@/decorators/time'
import UserModule from '@/store/user'
import * as api from '@/https/api'
import { message } from 'ant-design-vue'
import { getModule } from 'vuex-module-decorators'
import router from '@/router'
import store from '@/store'
import { LOGIN_WAY } from '@/views/Login/config'
import { LoginForm } from '../LoginForm'
import { validateOrReject } from 'class-validator'
import { sleep } from '@/utils/time'
import skyDebounce from '@/decorators/skyDebounce'
import skyLoading from '@/decorators/skyLoading'

const mUserModule = getModule(UserModule, store)

@Options({
  components: {
  }
})
export default class PhoneLogin extends Vue {
  public btnText = '发送验证码'
  public canSend = true;
  public countdown = 120;
  public allowSend = true;
  public timer = 0;
  public loading = false
  // public mLoginForm=new LoginForm({ phone: '18613094826', smsCode: '666666' })
  public mLoginForm = new LoginForm({ phone: '', smsCode: '' })
  @log
  @Watch('btnText')
  onBtnTextChange (val: string) {
    this.canSend = val === '发送验证码'
  }

  @Watch('timer')
  onTimerChange (timer: number) {
    if (!timer) {
      this.countdown = 120
      this.btnText = '重新发送'
    }
  }

  handlePhoneInput () {
    // 过滤非数字字符
    this.mLoginForm.phone = this.mLoginForm.phone.replace(/\D/g, '')
  }

  handleSmsCodeInput () {
    this.mLoginForm.smsCode = this.mLoginForm.smsCode.replace(/\D/g, '')
  }

  private get isEmptyPhone () {
    return !this.mLoginForm.phone || !this.mLoginForm.phone.trim()
  }

  @catchError()
  async onSendBtnClick () {
    if (this.isEmptyPhone) {
      throw new Error('手机号码未填写')
    }
    // 自动识别sms组需要校验的phone
    await validateOrReject(this.mLoginForm, { groups: ['sms'] })

    const postData = { mobile: this.mLoginForm.phone, type: 'login' }
    const res = await api.autoSendSms(postData)
    message.success(res.message)
    this.WhenTheTimeComes()
  }

  //  倒计时
  WhenTheTimeComes () {
    this.timer = window.setInterval(() => {
      if (this.countdown >= 1) {
        this.countdown--
        this.btnText = `${this.countdown}（秒）`
      } else {
        clearInterval(this.timer)
        this.timer = 0
      }
    }, 1000)
  }

  @skyDebounce()
  @catchError()
  @skyLoading('loading')
  async onLoginClick () {
    if (this.isEmptyPhone) {
      throw new Error('手机号码未填写')
    }
    // 自动识别phoneLogin组需要校验的phone和smsCode
    await validateOrReject(this.mLoginForm, { groups: ['phoneLogin'] })
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = 0
    }
    const postData = {
      code: +this.mLoginForm.smsCode,
      mobile: this.mLoginForm.phone,
      type: LOGIN_WAY.PHONE
    }
    const data = await api.login(postData)
    mUserModule.setBaseUserInfo({ ...data.data })
    const { data: userInfo } = await api.getUserInfo()
    mUserModule.setUserRegionId(userInfo.region_id)
    this.mLoginForm.reset()
    mUserModule.setUserInfo(userInfo)
    router.push('/yzt')
  }
}

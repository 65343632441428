import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-92e3f994"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_input, {
      maxlength: 11,
      onInput: _ctx.handlePhoneInput,
      allowClear: "",
      value: _ctx.mLoginForm.phone,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mLoginForm.phone) = $event)),
      placeholder: "请输入手机号码"
    }, null, 8, ["onInput", "value"]),
    _createVNode(_component_a_row, { gutter: 8 }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { span: 16 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              maxlength: 6,
              onInput: _ctx.handleSmsCodeInput,
              allowClear: "",
              value: _ctx.mLoginForm.smsCode,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.mLoginForm.smsCode) = $event)),
              placeholder: "请输入验证码"
            }, null, 8, ["onInput", "value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { span: 8 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              block: "",
              disabled: _ctx.timer,
              type: "primary",
              class: "btn-sms",
              onClick: _ctx.onSendBtnClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.btnText), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_a_button, {
      type: "primary",
      class: "login-button",
      loading: _ctx.loading,
      onClick: _ctx.onLoginClick
    }, {
      default: _withCtx(() => [
        _createTextVNode("登录")
      ]),
      _: 1
    }, 8, ["loading", "onClick"])
  ]))
}
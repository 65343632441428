/* eslint-disable @typescript-eslint/no-explicit-any */
import https from '@/https/http'
import { IListRes, IRes, RequestDto } from '@/interfaces/common/api'
import { AssignItem, IReport, LawType, LegalTypeItem, MissionList, ReportEventsItem, ReportInfo, ReportItem, ReportStatusItem, ViolationTypeItem, MissionItem, MessageList } from '@/interfaces/report.interface'
// import { IPanorama } from '@/interfaces/pano.interface'

export function getTaskList (params: any) { //  任务详情请求接口
  return https<any, IRes<IReport[]>>({ url: '/api/report/task', method: 'GET', params })
}

export function getReportList (params: RequestDto) { // 报告列表
  // return https<any, IRes<ReportItem[]>>({ url: '/api/work/report/list', method: 'GET', params })
  return https<any, IListRes<ReportItem[]>>({ url: '/api/report/center', method: 'GET', params })
}

export function getAssignList () { //  待分派人列表
  return https<any, IRes<AssignItem[]>>({ url: '/api/report/assignList', method: 'GET' })
}

export function getReportStatus () { // 报告状态列表
  return https<any, IRes<ReportStatusItem[]>>({ url: '/api/report/reportStatus', method: 'GET' })
}

export function getLegalType () { // 非违法类型列表
  return https<any, IRes<LegalTypeItem[]>>({ url: '/api/report/legalType', method: 'GET' })
}

export function getViolationType (params: any) { // 违法类型列表
  return https<any, IRes<ViolationTypeItem[]>>({ url: '/api/report/violationType', method: 'GET', params })
}

export function getViolationLaw (params: any) { // 违法类型对应法律条例
  return https<any, IRes<LawType>>({ url: '/api/report/violationLaw', method: 'GET', params })
}

export function getReportEvent () { // 报告事件状态列表
  return https<any, IRes<ReportEventsItem[]>>({ url: '/api/report/reportEvent', method: 'GET' })
}

export function getReportInfo (params: any) { // 报告单详情
  return https<any, IRes<ReportInfo>>({ url: '/api/report/info', method: 'GET', params })
}

export function registerLitigant (data: any) { // 登记当事人信息
  return https<any, IRes<any[]>>({ url: '/api/report/litigant', method: 'POST', data })
}

export function registerNotes (data: any) { // 登记违法信息
  return https<any, IRes<any[]>>({ url: '/api/report/notes', method: 'POST', data })
}

export function sendReportNotice (data: any) { // 下发整改通知书
  return https<any, IRes<any[]>>({ url: '/api/report/notice', method: 'POST', data })
}

export function saveReportRectifyRemark (data: any) { // 添加整改描述
  return https<any, IRes<any[]>>({ url: '/api/report/rectifyRemark', method: 'POST', data })
}

export function saveReportRectifyImg (data: any) { // 添加整改图片
  return https<any, IRes<any[]>>({ url: '/api/report/rectifyImg', method: 'POST', data })
}

export function apportionReport (data: any) { // 分派报告单
  return https<any, IRes<any[]>>({ url: '/api/report/apportion', method: 'POST', data })
}

export function checkReport (data: any) { // 核实报告单
  return https<any, IRes<any[]>>({ url: '/api/report/check', method: 'POST', data })
}

export function rectifyNeedReport (data: any) { // 已整改报告单
  return https<any, IRes<any[]>>({ url: '/api/report/rectifyNeed', method: 'POST', data })
}

export function caseApplyReport (data: any) { // 申请立案报告单
  return https<any, IRes<any[]>>({ url: '/api/report/caseApply', method: 'POST', data })
}

export function rectifyContinueReport (data: any) { // 继续整改报告单
  return https<any, IRes<any[]>>({ url: '/api/report/rectifyContinue', method: 'POST', data })
}

export function arrangeReport (data: any) { // 归档报告单
  return https<any, IRes<any[]>>({ url: '/api/report/arrange', method: 'POST', data })
}

export function caseConfirmReport (data: any) { // 确认立案报告单
  return https<any, IRes<any[]>>({ url: '/api/report/caseConfirm', method: 'POST', data })
}

export function verdictReport (data: any) { // 确认结案报告单
  return https<any, IRes<any[]>>({ url: '/api/report/verdict', method: 'POST', data })
}

export function legalBackReport (data: any) { // 非违法回退报告单
  return https<any, IRes<any[]>>({ url: '/api/report/legalBack', method: 'POST', data })
}

export function suggestCaseReport (data: any) { // 建议立案报告单
  return https<any, IRes<any[]>>({ url: '/api/report/suggestCaseApply', method: 'POST', data })
}

export function suggestRectifyReport (data: any) { // 督促整改报告单
  return https<any, IRes<any[]>>({ url: '/api/report/suggestRectify', method: 'POST', data })
}

export function illegalBackReport (data: any) { // 违法应整改回退报告单
  return https<any, IRes<any[]>>({ url: '/api/report/illegalBack', method: 'POST', data })
}

export function getMessageList (params: any) { // 获取消息列表
  return https<any, MessageList>({ url: '/api/report/messageList', method: 'GET', params })
}

export function deleMessage (params: any) { // 删除消息
  return https<any, IRes<any[]>>({ url: '/api/report/deleMessage', method: 'GET', params })
}

export function readMessage (params: any) { // 读取消息
  return https<any, IRes<any[]>>({ url: '/api/report/readMessage', method: 'GET', params })
}

export function getMissionList (params: any) {
  return https<any, MissionList>({ url: '/api/report/todoList', method: 'GET', params })
}

export function setStressReport (data: any) {
  return https<any, IRes<any[]>>({ url: '/api/report/setStress', method: 'POST', data })
}

export function updateReasonalbe (data: any) {
  return https<any, IRes<any[]>>({ url: '/api/report/update/reasonable', method: 'POST', data })
}

export function updateChangeStatus (data: any) {
  return https<any, IRes<any[]>>({ url: '/api/report/update/change', method: 'POST', data })
}

import { Module, VuexModule, Mutation, config, Action } from 'vuex-module-decorators'
import { GisItem } from '@/interfaces/gis.inteface'
import { IReport, ReportEventsItem, ReportInfo, ReportStatusItem, ViolationTypeItem } from '@/interfaces/report.interface'
import * as api from '@/https/api'
import { getRegionId } from '@/config'

config.rawError = true

export const REPORT_NAME = 'report'

export interface GetItemByNameFn {
  (name: string): GisItem | null;
}

@Module({ namespaced: true, name: REPORT_NAME })
export default class ReportModule extends VuexModule {
  public _reportList: IReport[] = [];
  public _reportInfo!: ReportInfo;

  public reportStatusList: ReportStatusItem[] = []
  public reportEventsList: ReportEventsItem[] = []
  public violationTypeList: ViolationTypeItem[] = []

  get reportList () {
    return this._reportList
  }

  get reportInfo () {
    return this._reportInfo
  }

  get reportStatus () {
    return this.reportStatusList
  }

  get reportEvents () {
    return this.reportEventsList
  }

  get violationType () {
    return this.violationTypeList
  }

  @Mutation
  setViolationType (types: ViolationTypeItem[]) {
    this.violationTypeList = types
  }

  @Mutation
  setReportStatus (status: ReportStatusItem[]) {
    this.reportStatusList = status
  }

  @Mutation
  setReportEvents (events: ReportEventsItem[]) {
    this.reportEventsList = events
  }

  @Mutation
  setReportList (reportList: IReport[]) {
    this._reportList = reportList
  }

  @Mutation
  setReportInfo (info: ReportInfo) {
    this._reportInfo = info
  }

  @Action({})
  async fetchReportList (params: any) {
    let { data } = await api.getTaskList({ ...params, region_id: getRegionId() })
    data = data.sort((a, b) => b.id - a.id)
    this.setReportList(data)
  }

  @Action({})
  async fetchReportInfo (report_id: number) {
    const { data } = await api.getReportInfo({ report_id })
    data.hotspotGroups = data.hotspotGroups.reverse()
    this.setReportInfo(data)
    return data
  }
}

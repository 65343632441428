import { ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'

@ValidatorConstraint()
export class IsMobilePhone implements ValidatorConstraintInterface {
  validate (text: string) {
    return /^1[3456789]\d{9}$/.test(text)
  }
}

@ValidatorConstraint()
export class isStrongPassword implements ValidatorConstraintInterface {
  validate (text: string) {
    const mPassReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$_!])[a-zA-Z\d$@_$#!]{8,}$/ // 密码总体格式匹配
    return mPassReg.test(text)
  }
}

@ValidatorConstraint()
export class isIDCard implements ValidatorConstraintInterface {
  validate (text: string) {
    const mPassReg = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/ // 身份证总体格式匹配
    return mPassReg.test(text)
  }
}

@ValidatorConstraint()
export class isBusinessLicense implements ValidatorConstraintInterface {
  validate (text: string) {
    const mPassReg = /(^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/ // 营业执照总体格式匹配
    return mPassReg.test(text)
  }
}

@ValidatorConstraint()
export class isOrganCer implements ValidatorConstraintInterface {
  validate (text: string) {
    const mPassReg = /[0-9A-HJ-NPQRTUWXY]{8}-[0-9A-HJ-NPQRTUWXY]/ // 组织机构证总体格式匹配
    return mPassReg.test(text)
  }
}

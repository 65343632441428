import { Center, LngLatPoint } from '@/interfaces/common/geo'
import { Krpano } from '@/interfaces/common/krpano'
import { PanoInfo, PanoSnashot } from '@/interfaces/pano.interface'
import { getLngLatPoints } from '@/utils/geoHelper'
import KrpanoConverter from '@skylar/krpano-converter'
import KrpanoTools from '@skylar/krpano-tools'

export const createPoint = (height: number) => (center: Center) => {
  if (!center.length) {
    return []
  }
  const rate = 700 / 150
  const range = Math.floor(rate * height)
  return getLngLatPoints(center, 6, range)
}

export const drawOnPano = (krpanoConverter: KrpanoConverter) => (krpanoTool: KrpanoTools) => (points: LngLatPoint[]) => {
  const xyPoints = [...points]
  const color = '0xff9800'
  const option = {
    bordercolor: color,
    borderalpha: 1,
    polyline: true,
    fillalpha: 0.1,
    fillcolor: color,
    borderwidth: 2

  }
  if (krpanoConverter && krpanoTool) {
    const tvPoints = xyPoints.map((p) => krpanoConverter.lonLatToAthAtv(p))
    krpanoTool.drawPolygon('serviceSpot', tvPoints, option)
  }
  return points
}

/**
 * @description 全景转换器比例修正函数
 * @param distance 点到全景中心点的距离
 * @returns 修正比例 《=1
 */
export const fixFunction = (distance: number): number => {
  if (distance > 500) {
    return 0.940
  }
  if (distance > 50 && distance <= 500) {
    return 0.950
  }
  if (distance <= 50) {
    return 1
  }
  return 1
}

/**
 * @description 获取快照中心点，如果没有读取全景点中心点
 * @returns Point84
 */
export function getSnapShotCenter (snapShot: PanoSnashot, panoramaInfo: PanoInfo): number[] {
  if (snapShot.PanoSnapshot_lng && snapShot.PanoSnapshot_lat) {
    return [+snapShot.PanoSnapshot_lng, +snapShot.PanoSnapshot_lat]
  }
  return [
    +panoramaInfo.panorama_lng,
    +panoramaInfo.panorama_lat
  ]
}

/**
 * @description 移除某类前缀或者所有热点
 * @param prefix 热点前缀
 */
export function removeAllHotSpots (krpano: Krpano, prefix?: string) {
  const hotspots = krpano.get('hotspot.count')

  for (let i = hotspots - 1; i >= 0; i--) {
    const hotspotName = krpano.get(`hotspot[${i}].name`)
    if (prefix) {
      if (hotspotName.includes(prefix)) {
        krpano.call(`removehotspot(${hotspotName})`)
      }
    } else {
      krpano.call(`removehotspot(${hotspotName})`)
    }
  }
}

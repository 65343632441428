/*
 * @Author: cccyh
 * @Date: 2023-07-10 09:22:29
 * @LastEditTime: 2023-07-10 15:20:44
 * @LastEditors: your name
 * @Description:
 * @FilePath: /vue-work/src/https/api/user.ts
 * 可以输入预定的版权声明、个性签名、空行等
 */
import https from '@/https/http'
import { IRes, RequestDto } from '@/interfaces/common/api'
import { ILoginDto, IUser, IUserBase, UserInfo } from '@/interfaces/user'

// 发送手机短信验证码
export function autoSendSms (params:RequestDto) {
  return https<any, IRes<any>>({ url: '/api/sms/sendSms', method: 'GET', params })
}
// 验证手机短信验证码
export function autoJudgeSms (params:RequestDto) {
  return https<any, IRes<any>>({ url: '/api/sms/judgeSms', method: 'GET', params })
}
// 获取扫描结果
export function getCodeResult ({ uuid }:Record<'uuid', 'string'>) {
  return https({ url: `/api/auth/wxCheck?uuid=${uuid}`, method: 'GET' })
}

// 获取二维码
export function getORCode () {
  return https({ url: '/api/auth/wxqr', method: 'GET' })
}
// 登录接口   (正式环境)
export function login (data:ILoginDto) {
  return https<ILoginDto, IRes<Pick<IUserBase, 'auth'|'access_token'>>>({ url: '/api/auth/login', method: 'POST', data })
}
// 获取 验证图片  (正式环境)
export function verification (data:RequestDto) {
  return https({ url: '/api/auth/captcha', method: 'POST', data })
}
//  鼠标抬起，验证验证码是否通过
export function verifyTrueAndFalse (data:RequestDto) {
  return https({ url: '/api/auth/check', method: 'POST', data })
}

export function getUserInfo () { // 获取用户详情
  return https<any, IRes<UserInfo>>({ url: '/api/user/info', method: 'GET' })
}

export function updateUser (data: RequestDto) { // 更新用户信息
  return https< any, any>({ url: '/api/user/update', method: 'POST', data })
}

export function resetUserPwd (data: RequestDto) { // 直接更新密码
  return https< any, any>({ url: '/api/user/resetPwd', method: 'POST', data })
}

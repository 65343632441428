import { Center, Point } from '@/interfaces/common/geo'
import * as turf from '@turf/turf'
import { Position } from '@turf/turf'
import { convertWKTToGeoJSON, geojsonW842gd } from './formatter'
import { ExtentItem } from '@/interfaces/pano.interface'

/**
 * @description 根据中心点（经纬度类型）获取其一定距离外的点的N个坐标
 * @author neohua87@gmail.com
 * @date 14/04/2023
 * @export
 * @param {*} center 中心点坐标[lng,lat]
 * @param {*} n
 * @param {*} distance 距离（米）
 * @return {*}  points
 */
export function getLngLatPoints (center: Center, n: number, distance: number) {
  const centerPoint = turf.point(center)
  const lnglatPoints = []
  for (let i = 0; i <= n; i++) {
    const bearing = 360 / n * i
    const point = turf.destination(centerPoint, distance / 1000, bearing, { units: 'kilometers' })
    const [lng, lat] = turf.getCoord(point)
    lnglatPoints.push({ lng, lat })
  }
  return lnglatPoints
}

export function getPointsCenter (coordinates: turf.Position[]) {
  const features = turf.points(coordinates)
  const feat = turf.center(features)
  return feat.geometry.coordinates
}

export function getCoordsFromWKT (wkt: string) {
  const geometry = convertWKTToGeoJSON(wkt) as turf.Polygon | turf.MultiPolygon
  let coordinates: Position[] = []
  if (geometry.type === 'Polygon') {
    coordinates = geometry.coordinates[0]
  }
  if (geometry.type === 'MultiPolygon') {
    coordinates = geometry.coordinates[0][0]
  }
  return coordinates
}

export function getCoordsFromGeojson (geometry: turf.Polygon | turf.MultiPolygon) {
  let coordinates: Position[] = []
  if (geometry.type === 'Polygon') {
    coordinates = geometry.coordinates[0]
  }
  if (geometry.type === 'MultiPolygon') {
    coordinates = geometry.coordinates[0][0]
  }
  return coordinates
}

export function getDistance (p1: Point, p2: Point) {
  const from = turf.point(p1)
  const to = turf.point(p2)
  return turf.distance(from, to, { units: 'meters' })
}

/**
 * @description 根据bbox计算中心点
 * @param bbox
 * @returns
 */
export function getCenterByBbox (bbox: ExtentItem) {
  // 解构 bbox
  const [minLon, minLat, maxLon, maxLat] = bbox

  // 计算中心点的经纬度
  const centerLon = (minLon + maxLon) / 2
  const centerLat = (minLat + maxLat) / 2

  return [centerLon, centerLat]
}
